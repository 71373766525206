<template>
   <!-- <div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader/>
		</div>
    </div> -->
	<div class="mb-5 mx-5 mt-3 ResponsiveControlePage">
		<div class="mb-2">
			<breadCrumbs :routesArray="routesArray"></breadCrumbs>
		</div>
		<div class="row w-full h-full flex mt-2">
			<div class="w-2/5">
				<h1 class="title">{{ presentTaskObject.controlTitle }}</h1>
			</div>
			<div class="flex w-3/5 justify-between">
				<div>
					<p class="text-sm">
						Audit Id : <b>{{ presentAuditObject.auditId }}</b>
					</p>
				</div>
				<div>
					<p class="text-sm">
						Audit Title : <b>{{ presentAuditObject.title }}</b>
					</p>
				</div>
			</div>
		</div>
		<div class="w-full minheight">
			<div class="w-full">
				<div class="text-sm">
					<div class="mainContainer tabStructure flex flex-row w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey">
						<div class="w-75 heightFixing pr-5">
							<div class="tabStructureDiv">
								<div class="flex flex-row borderBottom justify-between cursor-pointer tab-container">
									<div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
										<div>
											<div class="cursor-pointer">{{ tab.displayName }}</div>
											<div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
										</div>
									</div>
								</div>
								<div class="progressbarB rounded h-1"></div>
							</div>
							<div class="h-full">
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 1">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center mt-0.5 marginTopForDoc marginBottomForDoc justify-center">
												<div>
													<p class="header">CCID</p>
												</div>
											</div>
										</div>
										<p v-if="presentTaskObject.clientUniqueId" class="mt-1.5">{{ presentTaskObject.clientUniqueId != undefined || presentTaskObject.clientUniqueId == '' ? presentTaskObject.clientUniqueId : 'N/A' }}</p>
										<p v-else class="mt-1.5">-</p>
									</div>
									<div>
										<div class="border-b border-lightgrey">
											<p class="my-1.5 header">Description</p>
										</div>
										<p v-if="presentTaskObject.Description" class="mt-1.5 headerText">{{ presentTaskObject.Description != undefined || presentTaskObject.Description == '' ? presentTaskObject.Description : 'N/A' }}</p>
										<!-- <p v-else class="mt-1.5 headerText">{{ presentTaskObject.Description }}</p> -->
									</div>
									<div v-for="(control,index) in controlsArray" :key="control.framework" class="pt-2 pb-3">
										<div v-for="(detail) in control.controlsDetailsArray" :key="detail.controlNumber">
											<div class="border-b border-lightgrey flex flex-row">
												<div class="text-xs py-0.5 font-opensans bg-white font-bold h-auto flex items-center my-1.5 header">Framework {{index+1}} ({{ control.framework }})</div>
											</div>
											<div class="flex items-center">
												<div class="flex flex-row w-50 mt-2 mb-2">
													<div class="w-50 border-t border-l border-r border-b p-2 header border-lightgrey rounded-l-sm">
														<label class="controllabel">Number</label>
													</div>
													<div class="w-50 border-t border-r border-b p-2 header border-lightgrey rounded-r-sm">
														<div class="pl-3 flex items-center flex-wrap">
															<div :class="[getBorder(), 'text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto flex items-center']">
																{{ detail.controlNumber }}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="flex ">
												<label class="controllabel">Description </label>
												<div class="pl-9 pr-1 ml-1">:</div>
												<p v-if="detail.description == null || detail.description == undefined" class=" text-xs">{{ 'N/A' }}</p>
                                <p v-else class=" text-xs">{{detail.description}}</p>
											</div>
											<!-- <p class="mt-1.5">{{ control.controls }}</p> -->
										</div>
									<!-- <div>
										<div class="border-b border-lightgrey">
											<p class="my-1.5 header">Description</p>
										</div>
										<p class="mt-1.5">{{ presentTaskObject.controlAuditMethodology }}</p>
									</div> -->
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 2">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center mt-0.5 marginTopForDoc marginBottomForDoc justify-center">
												<div>
													<p class="header">Documents which are related to the controls</p>
												</div>
											</div>
										</div>
										<!-- <p class="mt-1.5">{{ descriptionObject._id }}</p> -->
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 3">
									<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center justify-center">
												<div>
													<!-- <p class="header">Comments</p> -->
												</div>
											</div>
											<div>
												<div class="buttonposition flex justify-center items-center">
													<div class="buttonposition flex justify-center items-center">
														<button class="w-24 flex filterStyles" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
															<img src="@/assets/filter.svg" class="h-3 mr-2.5" />
															Filters
														</button>
													</div>
												</div>
											</div>
										</div>
									<div>
                                        <tableData :tableRowData="tableRow" :headersData="Headers"></tableData>
                                    </div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 4">
									<div>
										<!-- <div class="border-b border-lightgrey">
											<div class="flex justify-between mb-1.5">
												<div class="header flex justify-center items-center">Files uploaded by Auditee/Asignee for Evidence</div>
												<div class="buttonposition flex justify-center items-center">
													 <input  type="file" id="upload" ref="element"/>
													<button class="btnprimary w-24 flex justify-center taskEditButtons">Attach</button> 
													<button @click="openFilePicker" for="upload" class="btnprimary w-24 flex justify-center taskEditButtons">Attach</button>
    												<input type="file" ref="fileInput" id="upload" @change.stop="handleFileChange" style="display: none" />
												</div>
											</div>
										</div> -->
										<div class="border-b border-lightgrey flex justify-between">
											<div><p class="my-1.5 header">Files</p></div>
											<div class="mb-2">
												<div class="buttonposition flex justify-center items-center">
                                                    <button @click="openFilePopup" for="upload" :class="TaskDetailsDropDown.taskStatus == 10401 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || TaskDetailsDropDown.taskStatus == 10418 ? 'btndisabled pointer-events-none paddingForAttachButton': 'btnprimary flex justify-center taskEditButtons'" class="w-24">Attach</button>
												</div>
											</div>
										</div>
										<!-- <p class="mt-1.5">Files</p> -->
										<div v-if="Object.keys(presentTaskObject).includes('files')" class="flex mt-5 gap-4 flex-wrap">
											<div v-for="doc in presentTaskObject.files" :key="doc">
												<div class="pdf w-full rounded-sm">
													<a :href="doc.url" class="flex w-full flex-row items-center">
														<img src="@/assets/pdfs.svg" alt="" />
														<p v-if="Object.keys(doc).includes('filename')" class="text-xs ml-2">{{doc.filename}}</p>
														<p v-else class="text-xs ml-1">{{ doc.name }}</p>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 5">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center justify-center">
												<div>
													<p class="header">Comments</p>
												</div>
											</div>
											<div>
												<div class="buttonposition flex justify-center items-center">
													<div class="buttonposition flex justify-center items-center">
														<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortComments()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
															Oldest
														</button>
														<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortComments()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
															Newest
														</button>
													</div>
												</div>
											</div>
										</div>
										<div>
											<div class="comment-box w-full mt-5">
												<!-- <div class="my-3"><textarea placeholder="Add a Comment" class="w-full text-xs border border-lightgrey rounded-sm p-2" rows="4" :disabled="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418" v-model="comment" @input="handleInput"></textarea></div> -->
												 <commentsEditor/>
												<!-- <div class="flex justify-end">
													<button @click="addComment" class="mr-2" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Add</button>
													<button @click="clearComment" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Cancel</button>
												</div> -->
											</div>
											<div v-if="Object.keys(presentTaskObject).includes('comments')" class="comments">
												<div v-for="(obj, index) in presentTaskObject.comments" class="border border-lightgrey mt-2 rounded-sm flex flex-row p-3" :key="index">
													<div class="w-1/5">
														<div class="header">{{ getPersonName(obj.createdBy) }}</div>
														<div class="fontSize10PX">{{ obj.createdAt }}</div>
													</div>
													<div class="w-4/5 text-xs text-justify">
													    <safeHtml :content="obj.comment"/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 6">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center justify-center">
												<div>
													<p class="header">Findings</p>
												</div>
											</div>
											<div>
												<div class="buttonposition flex justify-center items-center">
													<button @click="showFindingPopup()" :class="(TaskDetailsDropDown.taskStatus == 10401 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 ) || (presentTaskObject.auditor !== userInfo._id) ? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" class="w-32 flex justify-center " >Add Findings</button>
												</div>
											</div>
										</div>
										<div>
											<div class="text-xs pt-3">
												Result of the evaluation of the collected audit evidence against audit criteria . Audit findings indicate conformity or nonconformity . Audit findings can lead to the identification of risks , opportunities for improvement or recording good practices.
											</div>
											<div  v-if="findingsForTask.length > 0" class="">
												<div v-for="(obj, index) in findingsForTask" :key="index" class="mt-4">
													<div class="flex flex-row">
                                                            <div class="border-t border-l border-b p-2 header w-23 border-lightgrey rounded-tl-md">Finding ID</div>
                                                            <div  class="border header border-lightgrey w-77 p-2 rounded-tr-md">
                                                                {{obj.findingId}}
                                                            </div>
                                                        </div>
                                                        <div class="w-full flex flex-row">
                                                                   <div class="border-l w-23 border-b p-2 header border-lightgrey">Framework</div>
																   <div  class="w-27 border-b border-l border-lightgrey text-xs p-2">
																		<div class="mt-1">{{obj.frameworks}}</div>
																	</div>
                                                                   <div class="border-l w-23 border-b p-2 header border-lightgrey">Control Number</div>
                                                                    <div  class="border-r w-27 border-b border-l border-lightgrey p-2">
                                                                        <div class="pl-3 flex items-center flex-wrap">
																			<div v-if="obj.frameWorkControl != undefined" :class="getBorder()" class="text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto w-fit flex items-center" >
																				{{obj.frameWorkControl}}
																			</div>
																		</div>
                                                                    </div>
                                                        </div>
                                                        <div class="flex flex-row">
                                                            <div class="border-l border-b p-2 header w-23 border-lightgrey">Finding</div> <div  class="border-r border-b border-l border-lightgrey w-77 p-2 text-xs">{{ obj.findingComment }}</div>
                                                        </div>
                                                        <div class="flex flex-row">
                                                             <div class="border-l border-b p-2 header w-23 border-lightgrey">Evidence</div> <div  class="border-r border-b border-l border-lightgrey w-77 p-2 text-xs">{{ obj.findingEvidence }}</div>
                                                        </div>
                                                        <div class="w-full flex flex-row">
                                                                   <div class="border-l w-23 border-b p-2 header border-lightgrey rounded-bl-md">Finding Category</div>
																   <div  class="w-27 border-b border-l border-lightgrey  p-2">
																		<div v-if="obj.findingCategory === 1" class="flex items-center justify-center rounded w-28 h-5 text-error bg-highColor border-solid border text-xs border-error p-1 ">{{getCategory(obj.findingCategory)}}</div>
																		<div v-if="obj.findingCategory === 2" class="flex items-center justify-center rounded w-28 h-5 text-darkred bg-veryHighColor border-solid border text-xs border-darkred p-1 ">{{getCategory(obj.findingCategory)}}</div>
																		<div v-if="obj.findingCategory === 3" class="flex items-center justify-center rounded w-28 h-5 text-lightgreen bg-veryLowColor border-solid border text-xs border-lightgreen p-1 ">{{getCategory(obj.findingCategory)}}</div>
																		<div v-if="obj.findingCategory === 4" class="flex items-center justify-center rounded w-28 h-5 text-yellow bg-moderateColor border-solid border text-xs border-yellow p-1 ">{{getCategory(obj.findingCategory)}}</div>
																   </div>
                                                                   <div class="border-l w-23 border-b p-2 header border-lightgrey">Control Audit Result</div>
                                                                        <div class="border-r w-27 border-b border-l border-lightgrey p-2 rounded-br-md">
                                                                            <div v-if="obj.auditResult === 'Pass'" class="flex items-center justify-center rounded h-5 w-28 text-lightgreen bg-veryLowColor text-xs border-solid border border-lightgreen">{{obj.auditResult}}</div>
                                                                            <div v-if="obj.auditResult === 'Fail'" class="flex items-center justify-center rounded h-5 w-28 text-darkred bg-veryHighColor text-xs border-solid border border-darkred ">{{obj.auditResult}}</div>
                                                                        </div>
                                                            </div>
														
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 7">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center justify-center">
												<div>
													<p class="header">History</p>
												</div>
											</div>
											<div>
												<div class="buttonposition flex justify-center items-center">
													<div class="buttonposition flex justify-center items-center">
														<button class="btnprimary w-24 flex taskEditButtons" v-if="newHistory" @click="sortWorkLog()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
															Oldest
														</button>
														<button class="btnprimary w-24 flex taskEditButtons" v-if="!newHistory" @click="sortWorkLog()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
															Newest
														</button>
													</div>
												</div>
											</div>
										</div>

									<div v-for="work in workLogObject.taskHistory" :key="work" class="bg-white py-4 pl-4 mb-0.5 border-lightgrey border-b w-full">
                                            <div class="flex">
                                                <div v-if="Object.keys(work).includes('createdBy')" class="initials">
                                                    {{ extractInitials(getPersonName(work.createdBy)) }}
                                                </div>
                                                <div v-if="Object.keys(work).includes('updatedBy')" class="initials">
                                                    {{ extractInitials(getPersonName(work.updatedBy)) }}
                                                </div>
                                                <div class="logContainer w-93">
                                                    <div class="flex mt-1">
                                                        <p v-if="Object.keys(work).includes('createdBy')" class="font-semibold text-xs">
                                                            {{ getPersonName(work.createdBy) }}
                                                        </p>
                                                        <p v-else-if="Object.keys(work).includes('updatedBy')" class="font-semibold text-xs">
                                                            {{ getPersonName(work.updatedBy) }}
                                                        </p>
 
                                                        <p v-if="work.actionEntity == 10301" class="mx-2 text-xs">{{ 'Created the Task on' }}</p>
                                                        <p v-else-if="work.statusCode != undefined && work.statusCode == 10418" class="mx-2 text-xs">{{ 'Terminated the Task on' }}</p>
                                                        <p v-else-if="work.actionEntity == 10302" class="mx-2 text-xs">{{ 'Updated the Task on' }}</p>
 
                                                        <p v-if="Object.keys(work).includes('createdAt')" class="font-semibold  text-xs">{{ formatTime(work.createdAt) }}</p>
                                                        <p v-if="Object.keys(work).includes('updatedAt')" class="font-semibold text-xs">{{ formatTime(work.updatedAt) }}</p>
                                                    </div>
                                                    <div class="mt-3" v-if="Object.keys(work).includes('additionalInfo')">
                                                        <div class="flex" v-if="Object.keys(work.additionalInfo).includes('comment')">
                                                            <p class="font-semibold text-xs widthForCommentBox">{{ 'Comment :' }}</p>
                                                            <p class="text-xs w-full text-justify">
																<safeHtml :content="work.additionalInfo.comment"/>
															</p>
                                                        </div>
                                                        <div class="flex" v-if="Object.keys(work.additionalInfo).includes('findingId')">
                                                            <p class="font-semibold text-xs">{{'Added the finding -'}}</p>
                                                            <p class="mx-2 text-xs">{{ work.additionalInfo.findingId }}</p>
                                                        </div>
                                                        <div class="flex" v-if="Object.keys(work.additionalInfo).includes('evidence')">
                                                            <p class="font-semibold text-xs">{{'Uploaded the Evidence -'}}</p>
                                                            <p class="mx-2 text-xs">{{ work.additionalInfo.evidence }}</p>
                                                        </div>
														<div class="flex" v-if="Object.keys(work.additionalInfo).includes('assignee')">
                                                            <p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
                                                            <p class="mx-2 text-xs">{{ getPersonName(work.additionalInfo.assignee) }}</p>
                                                        </div>
                                                        <div class="flex items-center" v-if="Object.keys(work.additionalInfo).includes('previousStatus')">
                                                            <div>
                                                                <p class="font-semibold text-xs">{{'Updated The Status :'}}</p>
                                                            </div>
                                                            <div class="flex ml-4">
                                                                <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorAuditBg(work.additionalInfo.previousStatus)">
                                                                    <div :class="colorAuditText(work.additionalInfo.previousStatus)">
                                                                        {{ getStatusLabel(work.additionalInfo.previousStatus) }}
                                                                    </div>
                                                                </div>
                                                                <div class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
                                                                <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorAuditBg(work.additionalInfo.updatedStatus)">
                                                                    <div :class="colorAuditText(work.additionalInfo.updatedStatus)">
                                                                        {{ getStatusLabel(work.additionalInfo.updatedStatus) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>
						<div class="w-25 border taskDetailsRightBar rounded-sm border-lightgrey">
							<div class="m-3">
								<div class="pb-2 border-b border-lightgrey">
									<h1>Task Details</h1>
								</div>
								<div class="pt-1.5 overflow-y-scroll thirtyWidthToggle">
									<div class="flex flex-col">
										<label class="controllabel">Task Status</label>
										<input disabled v-if="TaskDetailsDropDown.taskStatus == 10418" type="text" placeholder="Status" class="terminateBox inputboxstyling mt-2 mb-3" value="Terminated" />
										<input disabled v-else-if="TaskDetailsDropDown.taskStatus == 10403" type="text" placeholder="Status" class="completedBox inputboxstyling mt-2 mb-3" value="Completed" />
										<input disabled v-else-if="TaskDetailsDropDown.taskStatus == 10417" type="text" placeholder="Status" class="terminateBox inputboxstyling mt-2 mb-3" value="Not Applicable" />
										<div v-else>
											<select
												class="mt-2 mb-3 custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
												:class="changeBackground()"
												:disabled="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 || TaskDetailsDropDown.taskStatus == 10418"
												v-model="TaskDetailsDropDown.taskStatus"
												@change="updateTaskStatus(TaskDetailsDropDown.taskStatus)"
											>
												<option value="" selected disabled hidden>Please select one</option>
												<option v-for="i in filteredTaskStatusData" :value="i.id" :key="i"  :disabled="i.id == 10401" :class="i.id == 10401 ? 'taskStatusOptionsForNotStarted' : 'taskStatusOptions'">
													{{ i.status }}
												</option>
											</select>
										</div>
										<!-- <select
											class="custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
											v-else
											:disabled="presentAuditObject.statusCode == 10418 || TaskDetailsDropDown.taskStatus == 10418"
											v-model="TaskDetailsDropDown.taskStatus"
										>
											<option value="" selected disabled hidden>Please select one</option>
											<option v-for="i in terminateArray" :value="i.id" :key="i">
												{{ i.status }}
											</option>
										</select> -->

									</div>
		
									
									<div>
										<div>
											<label class="controllabel">Assigned To</label>
											<div class="mt-2 mb-3" v-if="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 || presentTaskObject.auditor == userInfo._id || (presentTaskObject.auditee != userInfo._id && presentAssignee != userInfo._id)">{{presentTaskObject.assignee != undefined ? presentTaskObject.assignee.label : 'N/A'}}</div>
											<div v-else>
												<multiselect
												v-model="presentTaskObject.assignee"
												:options="userOptions"
												:showNoOptions="false"
												@search-change="asyncFind"
												:limit-text="limitText"
												:multiple="false"
												:close-on-select="true"
												:clear-on-select="true"
												label="label"
												open-direction="bottom"
												:hide-selected="true"
												track-by="value"   
												@select="updateAssignedMember(presentTaskObject.assignee)" 
												:searchable="true"
												:hideArrow="false"
												placeholder="Assigned-To"
												deselectLabel=""
												selectLabel=""
												class="custom-multiselect mt-2 mb-3"
											>
												<template v-slot:noResult>
													<span class="text-xs">No Members Found</span>
												</template>
										    	</multiselect>
											</div>
										</div>
									</div>
									<div class="mt-3">
                                        <label class="controllabel">Auditor</label><br>
										<div class="my-1 mt-2 text-sm" v-if="presentTaskObject.auditor != undefined || presentTaskObject.auditor != null">{{ getPerson(presentTaskObject.auditor) }}</div>
										<p class="mt-2 text-sm" v-else>N/A</p>
                                    </div>
                                    <div class="mt-3">
                                        <label class="controllabel">Auditee</label><br>
										<div class="my-1 mt-2 text-sm" v-if="presentTaskObject.auditee != undefined || presentTaskObject.auditee != null">{{ getPersonName(presentTaskObject.auditee) }}</div>
										<p class="mt-2 text-sm" v-else>N/A</p>
                                    </div>
                                    <div class="mt-3">
                                        <label class="controllabel">Team</label><br>
										<div class="my-1 mt-2 text-sm" v-if="presentTaskObject.department != undefined || presentTaskObject.department != null">{{ getTeamName(presentTaskObject.department) }}</div>
										<p class="mt-2 text-sm" v-else>N/A</p>
                                    </div>
									<div class="mt-3">
                                        <label class="controllabel">Entity</label><br>
										<div class="my-1 mt-2 text-sm" v-if="presentTaskObject.department != undefined || presentTaskObject.department != null">{{ getLocationName(presentTaskObject.department) }}</div>
										<div class="my-1 mt-2 text-sm" v-else>{{'N/A'}}</div>
										<!-- <p class="mt-2 text-sm" v-else>N/A</p> -->
                                    </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<popup v-if="importpopUp" :popUp="importpopUp">
			<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
			<div class="relative w-full max-w-xl">
			<!--content-->
			<div class="border-0 rounded relative flex flex-col w-full bg-white h-screen">
				<!--header-->
				<div class="flex items-center justify-between popupheadingcontainer rounded">
				<h1>Import files</h1>
				<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCancelIcon()">
					<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
				</button>
				</div>
				<div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
				<div class="impactcontainer popupbodycontainer">
				<div class="formcontainer flex flex-col">
					<div class="row flex flex-col mt-2">
					<div>
						<label class="controllabel">File Name </label>
						<input class="inputboxstyling mt-2" type="text" v-model="inputFileName" placeholder="Filename"  :class="{'is-invalid': v$.inputFileName.$error }"/>
						<div v-if="v$.inputFileName.$error " class="text-red-500">
							<div class="error-text" v-if="v$.inputFileName.required.$invalid ">Required</div>
						</div>
					</div>
					<div class="pt-2">
						<label class="controllabel">Attachment</label>
						<div class="w-auto evidenceHeight bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2" :class="{'is-invalid':v$.fileToUpload.$error}">
							<!-- <label htmlFor="upload" class="controllabel">Upload File</label> -->
							<input type="file" ref="fileInput" class="hidden" id="upload" @change="handleFileUpload"/>
							<label for="upload" class="float-right cursor-pointer text-white flex justify-center items-center w-9 h-7 bg-primary"><img src="@/assets/Browse.svg" /></label>
							<div v-if="fileName" class="text-xs px-1 pt-1.5">{{ fileName }}</div>
						</div>
					</div>
					
					<div v-if="v$.fileToUpload.$error" class="text-red-500">
						<div class="error-text" v-if="v$.fileToUpload.$invalid">File upload is required</div>
					</div>
					</div>
				</div>
				</div>
				<div class="buttonposition flex justify-center items-center">
					<button class="btnprimary" @click="handleFileChange" :class="(inputFileName.length == 0) ? 'btndisabled pointer-events-none ' : 'btnprimary'" :disabled="clickOnButton">Save</button>
				</div>
			</div>
			</div>
			</div>
		</popup>
		<popup v-if="showPopUp" :popUp="showPopUp">
			<template v-slot:header>
				<div class="flex items-center justify-between m-4 rounded">
					<h1>Findings</h1>
					<button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
						<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
					</button>
				</div>
			</template>
			<fieldset>
				<div class="statuscontainer popupbodycontainer">
					<div class="formcontainer flex flex-col">
						<div class="row flex flex-row w-full mt-3">
							<div class="w-1/2 pr-2">
								<label class="controllabel">Finding-Id</label>
								<input type="text" v-model="findingsObject.findingId" disabled class="inputboxstyling mt-2 mb-3"/>
								<div v-if="v$.findingsObject.findingId.$error" class="text-red-500">
									<div class="error-text" v-if="v$.findingsObject.findingId.required.$invalid">Required</div>
								</div>
							</div>
							<div class="w-1/2 pl-2">
								<label class="controllabel">Finding Category</label>
								<select class="mt-2 mb-3 cursor-pointer" v-model="findingsObject.findingCategory" :class="{ 'is-invalid': v$.findingsObject.findingCategory.$error,'inputboxstylingForSelectAtPopup': findingsObject.findingCategory === '','stylingForInputboxstylingForSelectAtPopup': findingsObject.findingCategory !== ''}" @change="updateAuditResult">
									<option value="" selected disabled hidden>Please select one</option>
									<option v-for="i in findingCategoryData" :value="i.id" :key="i.id">
										{{ i.value }}
									</option>
								</select>
								<div v-if="v$.findingsObject.findingCategory.$error" class="text-red-500">
									<div class="error-text" v-if="v$.findingsObject.findingCategory.required.$invalid">Required</div>
								</div>
							</div>
						</div>
						<div class="row flex flex-row w-full">
							<div class="w-1/2 pr-2">
								<label class="controllabel">Framework</label>
								<select class="mt-2 mb-3 cursor-pointer" @change="changeFrameWorkControlNums()" v-model="findingsObject.frameworks" :class="{ 'is-invalid': v$.findingsObject.frameworks.$error,'inputboxstylingForSelectAtPopup': findingsObject.frameworks === '','stylingForInputboxstylingForSelectAtPopup': findingsObject.frameworks !== ''}">
									<option value="" selected hidden>Please select one</option>
									<option v-for="i in auditFrameworkArray" :value="i" :key="i">
										{{ i }}
									</option>
								</select>
								<div v-if="v$.findingsObject.frameworks.$error" class="text-red-500">
									<div class="error-text" v-if="v$.findingsObject.frameworks.required.$invalid">Required</div>
								</div>
							</div>
							<div class="w-1/2 pl-2">
								<label class="controllabel">Control Number</label>
								<select class="mt-2 mb-3 cursor-pointer" :disabled="findingsObject.frameworks == ''" v-model="findingsObject.frameWorkControl" :class="{ 'is-invalid': v$.findingsObject.frameWorkControl.$error,'inputboxstylingForSelectAtPopup': findingsObject.frameWorkControl === '','stylingForInputboxstylingForSelectAtPopup': findingsObject.frameWorkControl !== ''}" @change="updateAuditResult">
									<option value="" selected disabled hidden>Please select one</option>
									<option v-for="i in selectedControlNumbers" :value="i" :key="i">
										{{ i }}
									</option>
								</select>
								<div v-if="v$.findingsObject.frameWorkControl.$error" class="text-red-500">
									<div class="error-text" v-if="v$.findingsObject.frameWorkControl.required.$invalid">Required</div>
								</div>
							</div>
						</div>
						<div class="w-full">
							<label class="controllabel">Findings</label>
							<textarea v-model="findingsObject.findingComment" class="inputboxstyling mt-2 mb-3 resize-none" placeholder="Findings" rows="3" :class="{ 'is-invalid': v$.findingsObject.findingComment.$error }"></textarea>
							<div v-if="v$.findingsObject.findingComment.$error" class="text-red-500">
								<div class="error-text" v-if="v$.findingsObject.findingComment.required.$invalid">Required</div>
							</div>
						</div>
						<div class="w-full">
							<label class="controllabel">Evidence</label>
							<!-- <textarea v-model="findingsObject.findingEvidence" class="inputboxstyling resize-none" placeholder="Finding related to Evidence" :class="{ 'is-invalid': v$.findingsObject.findingEvidence.$error }"></textarea> -->
							<multiselect
								v-model="findingsObject.findingEvidence"
								:options="evidenceArray"
								:show-labels="false"
								:multiple="false"
								:hide-selected="true"
								open-direction="bottom"
								:searchable="true"
								:hideArrow="false"
								placeholder="Evidences"
								class=" userSelect mt-2 mb-3"
								:class="{'is-invalid': v$.findingsObject.findingEvidence.$error}"
							>
								<template v-slot:noResult>
									<span class="text-xs">No Evidences Found</span>
								</template>
							</multiselect>
							<div v-if="v$.findingsObject.findingEvidence.$error" class="text-red-500">
								<div class="error-text" v-if="v$.findingsObject.findingEvidence.required.$invalid">Required</div>
							</div>
						</div>
						<div class="w-1/2 mt-3">
							<label class="controllabel">Control Audit Result</label>
							<input class="inputboxstyling mt-2 mb-3 cursor-pointer" disabled v-model="findingsObject.auditResult" type="text" placeholder="Result" />
							<!-- <div v-if="v$.findingsObject.auditResult.$error" class="text-red-500">
								<div class="error-text" v-if="v$.findingsObject.auditResult.required.$invalid">Required</div>
							</div> -->
						</div>
					</div>
				</div>
				<div class="buttonposition flex justify-center items-center mb-4 mx-0">
					<button class="mr-1.5" :class="(JSON.stringify(findingsObject) != JSON.stringify(findingsObjectDummy)) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
					<button v-if="!findingsObject._id" :class="(JSON.stringify(findingsObject) != JSON.stringify(findingsObjectDummy)) ? 'btnprimary' : 'btndisabled pointer-events-none'" :disabled="clickOnButtonForFinding" @click="saveEnteredFinding()">Save</button>
				</div>
			</fieldset>
		</popup>
	</div>
</template>
<style scoped>
.filterStyles{
	padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.widthForCommentBox{
	width : 75px;
}
.taskStatusOptions{
  background-color: white; 
  color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
 .taskStatusOptionsForNotStarted{
    background-color: #e9e9e9; 
  color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
.completedBox{
	background-color: #00B050 !important;
	color: white;
	pointer-events: none;
}
.terminateBox{
	background-color: #FF0000 !important;
	color: white;
	pointer-events: none;
}
.progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
}
.numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 1px solid #152A43;
    border-width: 2px;
}
.progress {
	background: #152a43;
	position: relative;
	border-radius: 4px;
	z-index: 2;
}
.primaryBackground{
	background-color: #152a433b
}
.descriptionToggle {
	height: 97%;
}
.tabStructure {
	height: calc(100vh - 165px);
}
.thirtyWidthToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.thirtyWidthToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.thirtyWidthToggle{  
     height:  calc(100vh - 250px);
}
.descriptionToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.descriptionToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.heightFixing {
	height:  calc(100vh - 220px);
	/* height : 64vh; */
}
option {
	background-color: #ffffff;
	color: #000000;
	border-color: pink transparent transparent transparent;
}
.textColorWhite {
	color: white;
}
.custom-select {
    background-image: url("../assets/Collapse.svg") !important;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    background-position: right 15px center; /* Change the px value here to change the distance */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.marginBottomForDoc{
	margin-bottom : 3px;
}
.marginTopForDoc{
	margin-top : 2.5px;
}
.marginBottomZero{
	margin-bottom : 0px;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.labelWidth{
	min-width: 7rem;
}
.framework{
	padding: 12px 170px 12px 12px;
}
.findingCategory{
	padding: 12px 80px 12px 12px;
}
.btnprimary1 {
	cursor: pointer;
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-family: Open Sans;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.evidenceHeight{
	height: 29px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import breadCrumbs from '@/components/breadCrumbs.vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import popup from '@/components/popup.vue';
import tableData from '@/components/tableData.vue';
import { groupBy } from 'lodash';
import { emitter, toast } from '@/main';
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import commentsEditor from '@/components/commentsEditor.vue';
import Multiselect from 'vue-multiselect';
import safeHtml from "@/components/safeHtml.vue";
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			fileToUpload: null,
			selectedTabIndex: 1,
			tabHead: [
				{ displayName: 'Description', id: 1 },

				{ displayName: 'Documents', id: 2 },

				{ displayName: 'Related Tasks', id: 3 },

				{ displayName: 'Evidence', id: 4 },

				{ displayName: 'Comments', id: 5 },

				{ displayName: 'Findings', id: 6 },

				{ displayName: 'History', id: 7 }
			],
			routesArray: [],
			Headers: ['TaskId','Title','Frequency','Status'],
			selectedControlNumbers:[],
			tableRow: [],
			TaskDetailsDropDown: {
				taskStatus: '',
				assignedToDropDown: ''
			},
			userOptions:[],
			evidenceArray:[],
			terminateArray:[
				{
					id: 10418,
					status: 'Terminated'
				},
			],
			taskStatusData: [
				{
					id: 10401,
					status: 'Not Started'
				},
				{
					id: 10415,
					status: 'In Review'
				},
				{
					id: 10414,
					status: 'Pending Evidence'
				},
				{
					id: 10416,
					status: 'Evidence Uploaded'
				},
				{
					id: 10403,
					status: 'Completed'
				},				
                {
                    id: 10417,
                    status: 'Not Applicable'
                }
			],
			findingCategoryData: [
				{
					id: 1,
					value: 'Minor'
				},
				{
					id: 2,
					value: 'Major'
				},
				{
					id: 3,
					value: 'OFI'
				},
				{
					id: 4,
					value: 'Observation'
				}
			],

			assignedToData: [
				{
					id: 1,
					name: 'Nikhil Kapuganti'
				},
				{
					id: 2,
					name: 'Abhiram Kadari'
				},
				{
					id: 3,
					name: 'Venkatesh Abisetty'
				}
			],
			oldComments: true,
			newHistory: true,
			comment: '',
			comments: [],
			btnDisabled: true,
			showPopUp: false,
			auditFrameworkArray: [],
			findingsObject: {
				findingId:'',
				frameworks: '',
				frameWorkControl:'',
				findingCategory: '',
				findingComment: '',
				findingEvidence: '',
				auditResult: ''
			},
			findingsObjectDummy: {},
			presentAuditData: {},
			selectedOption: '',
			selectedBG: '',
			workLogObject: [],
			optionsUserArray: [],
			orgUsersList: [],
			presentAuditObject: {},
			tasksForAudit: [],
			presentTaskObject: {},
			personObj : '',
			controlsArray : [],
			controlDetailsArray : [],
            controlsArrayAllControls : [],
            controlsDetailsList : [],
			teamObj : '',
            selectedTabObj:{},
            users : [],
			allFrameWorkControls:[],
			locationObject:[],
			importpopUp: false,
			isInputFileNameValid: true,
			fileSelected: false,
			isFileValid: true,
			inputFileName: '',
			fileChanged : false,
			fileName:'',
			findingsForTask:[],
			evidenceOptions:[],
			presentAssignee:'',
			TeamLeaderValue:'',
			clickOnButton:false,
			clickOnButtonForFinding:false,
			// navIndexNums: { name: '', index: '' },
		};
	},
	validations() {
		return {
			inputFileName:{required},
			fileToUpload: { required },
			findingsObject: {
				findingId:{required},
				frameworks: { required },
				frameWorkControl:{required},
				findingCategory: { required },
				findingComment: { required },
				findingEvidence: { required }
				// auditResult: { required }
			}
		};
	},
	components: {
		breadCrumbs,
		popup,
		Multiselect,
		tableData,
		commentsEditor,
		safeHtml,
		dotsLoader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		filteredTaskStatusData: function ():any {
			return this.taskStatusData.filter((i:any) => {
				return !((i.id == 10403 || i.id == 10417) && this.TeamLeaderValue != this.userInfo._id);
			});
		}
	},
	methods: {
		limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 1){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase()) && obj.value != this.presentTaskObject.auditor})
			}
		},
		
		changeFrameWorkControlNums(){
			console.log('saidwiudhks',this.findingsObject.frameworks)
			this.selectedControlNumbers = []
			this.findingsObject.frameWorkControl = ''

			if(this.findingsObject.frameworks == 'Internal Controls'){
				this.selectedControlNumbers.push(this.presentTaskObject.controlNumber);
				this.findingsObject.frameWorkControl = this.presentTaskObject.controlNumber
			}else{
				let obj:any = this.presentTaskObject.frameworks.find((obj:any)=>{return obj.framework == this.findingsObject.frameworks});
				
				if(obj!= undefined){
					obj.controlsDetailsArray.map((ctrlObj:any)=>{this.selectedControlNumbers.push(ctrlObj.controlNumber);})
				}
			}
		},
		async getTaskHistoryObject() {
			let auditId = this.$route.params.auditId;
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/task/${this.$route.params.taskId}/getHistory`).then((result: any) => {
				const mainObject = result.data;
				// console.log('dathhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhht is', result.data);
				this.workLogObject = mainObject;
				console.log('workhossss', this.workLogObject);
			});
				this.workLogObject.taskHistory.sort().reverse();
		},
		async getAllAudits() {
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audits/getAll`).then((result: any) => {
				result.data.forEach((info: any) => {
					this.allAudits.push(info);
				});
				console.log('allAudits', this.allAudits);
			});
		},
		getCategory(num: any) {
			let result = this.findingCategoryData.find((obj: any) => {
				return obj.id == num;
			});
			return result.value;
		},
		getLocationName(teamId: any) {
			let teamObj = this.users.find((obj: any) => {
                return obj._id == teamId;
            });
            if (teamObj == undefined) {
                return 'N/A';
            } else if(teamObj.entityType != undefined && teamObj.entityType == 20301) {
                return 'Global'
            }else if(teamObj.entityType != undefined && teamObj.entityType == 20303){
				return teamObj.entityName
			}else{
				return 'N/A';
			}
        },
		onCancelIcon(){
			this.importpopUp = false;
			this.clickOnButton = false;
			this.inputFileName = ''
			this.fileName = ''
			this.fileToUpload=null
			this.v$.$reset();
		},
		getStatusLabel(num:any){
			let result = this.taskStatusData.find((obj:any)=>{return obj.id == num})
			return result.status
		},
		updateAuditResult() {
			const category = this.findingsObject.findingCategory;
			if (category === 1 || category === 2) {
				this.findingsObject.auditResult = 'Fail';
			} else if (category === 3 || category === 4) {
				this.findingsObject.auditResult = 'Pass';
			} else {
				this.findingsObject.auditResult = '';
			}
		},
		// scrollTabIntoView(tabId : any) {
		// 	this.$nextTick(() => {
		// 		const tabElement = this.$el.querySelector(`.tab:nth-child(${tabId})`);
		// 		if (tabElement) {
		// 		tabElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
		// 		}
		// 	});
		// },
		getTeamName(team: any) {
            let teamObj = this.users.find((obj: any) => {
                return obj._id == team;
            });
            if (teamObj == undefined) {
                return '';
            } else {
                return teamObj.displayName;
            }
        },
		getBorder(){
            return `numberBorder numberNavy`
        },
		async getAllActiveUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
				this.orgUsersList = res.data;
				for (var i = 0; i < this.orgUsersList.length; i++) {
					// if(this.orgUsersList[i].isActive == true){
						this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
					// }
				}
			});
			console.log('this.orgUsersList', this.orgUsersList);
		},
		async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
		async getAllTeams() {
            this.teamObject = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
            });
            console.log('users',this.users)
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].displayName, value: this.users[i]._id });
            }
            console.log('teamObject',this.teamObject)
        },
		getPersonName(person: any) {
			let personObj = this.orgUsersList.find((user: any) => {
              
				return user._id == person;
			});
			if (personObj == undefined) {
				return '';
			} else {
				return personObj.name;
			}
		},
		getPerson(person: any) {
			this.personObj = this.orgUsersList.find((user: any) => {
              
				return user._id == person;
			});
			if (this.personObj == undefined) {
				return '';
			} else {
				return this.personObj.name;
			}
		},
		sortWorkLog() {
			this.newHistory = !this.newHistory;
			this.workLogObject.taskHistory.sort().reverse();
		},
		sortComments() {
			this.oldComments = !this.oldComments;
			if(this.presentTaskObject.comments != undefined){
				this.presentTaskObject.comments.sort().reverse();
			}
		},
		formatTime(time: any) {
			let now = moment(time);
			return now.format('DD-MM-YYYY, hh:mm:ss');
		},
		extractInitials(name: any) {
			const names = name.split(' ');
			return names
				.map((name: any) => name[0])
				.join('')
				.toUpperCase();
		},
		changeBackground() {
			if (this.TaskDetailsDropDown.taskStatus == 10401) {
				return 'bg-disabledgrey textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10415) {
				return 'bg-yellow textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10414) {
				return 'bg-electricGreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10416) {
				return 'bg-primary textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10403) {
				return 'bg-lightgreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10417 || this.TaskDetailsDropDown.taskStatus == 10418) {
                return 'bg-darkred textColorWhite';
            }else {
				return '';
			}
		},
		checkAuditor(id:any){
			console.log("this.userInfo",this.userInfo)
			if(id == this.userInfo._id){
				return true
			} else if(id == undefined || id == null){
				return false
			} else {
				return false
			}
		},
		async updateTaskStatus(status: any) {
			let payload: any = { statusCode: status };
			await this.updateAuditTask(4, payload);
		},
		async updateAssignedMember(obj: any) {
			let payload: any = { assignee: obj.value };
			await this.updateAuditTask(5, payload);
		},
		handleInput() {
			this.btnDisabled = this.comment.length === 0;
		},
		async addComment() {
			if (this.comment.trim() !== '') {
				const newComment: any = {
					comment: this.comment
				};
				await this.updateAuditTask(1, newComment);
				// this.comments.push(newComment);
				if(this.presentTaskObject.comments != undefined){
					this.presentTaskObject.comments.sort().reverse();
				}
				this.comment = '';
				this.btnDisabled = true;
			}
		},
		clearComment() {
			this.comment = '';
			this.btnDisabled = true;
		},
		async toggleTabs(obj: any) {
			// this.scrollTabIntoView(obj.id);
			this.selectedTabIndex = obj.id;
			this.selectedTabObj = obj;
			sessionStorage.setItem('selectedTab', obj.id);
		},
		showFindingPopup() {
			this.showPopUp = !this.showPopUp;
			
			if(this.showPopUp == true){
                let uuid = uuidv4();
                const truncatedUuid = uuid.replace(/-/g,'').substring(0, 8).toUpperCase();
 
                this.findingsObject.findingId = truncatedUuid;
                this.findingsObjectDummy.findingId = truncatedUuid;
            }
			if(Object.keys(this.findingsObject).includes('createdAt')){
				delete this.findingsObject.createdAt;
				delete this.findingsObject.createdBy;
				delete this.findingsObject.additionalInfo;
			}
		},
		onCancelButtonAddPopUp() {
			this.showPopUp = false;
			this.onCancelbtn();
		},
		openFilePopup() {
			// this.$refs.fileInput.click();
			this.importpopUp = true
		},
		updateFileName(event: any) {
			// const selectedFile = event.target.files[0];
			// this.newDocumentObj.file = selectedFile;
			let file = this.$refs.fileInput.files[0];

			if (file && !file.type.match('application/pdf') && !file.name.endsWith('.doc') && !file.name.endsWith('.docx')) {
				alert('Please select a PDF, DOC, or DOCX file.');
				return;
			} else {
				this.fileChanged = true;
				this.fileToUpload.file = file;
				console.log("this.fileToUpload.file",this.fileToUpload.file)
			}
		},
		handleFileUpload(event: any) {
            const file = this.$refs.fileInput.files[0];
            if (file) {
                this.fileName = file.name;
                this.fileToUpload = file
                console.log("file",file)
                 const fileNameParts = file.name.split('.');
                // console.log('fileNameParts1',fileNameParts)
                fileNameParts.pop(); // Remove the extension
                // console.log('fileNameParts2',fileNameParts)
                const nameWithoutExtension = fileNameParts.join('.');
                // console.log('fileNameParts',nameWithoutExtension)
                if (this.inputFileName.trim() === '') {
                this.inputFileName = nameWithoutExtension;
                }
                // console.log('inputFileName',this.inputFileName)
            } else {
                this.fileName = '';
                this.inputFileName = '';
                console.log('inputFileName',this.inputFileName)
            }
        },
		async handleFileChange(event: any) {
			this.clickOnButton = true;
			let data = this.$refs.fileInput.files[0];
			this.v$.inputFileName.$touch()
			this.v$.fileToUpload.$touch()
			console.log("data",data)
			if(((!this.v$.fileToUpload.$invalid) && (!this.v$.inputFileName.$invalid))){
			this.importpopUp = !this.importpopUp
			let fileExtension = data.name.split('.').pop();
			if (fileExtension == 'pdf') {
				const fileDataFormat = await this.getConvertDataForFile(data);
				let uuid = uuidv4();
				let payload = { fileData: fileDataFormat, name: `${uuid}.${fileExtension}` };
				let folderName = 'audit';
				try {
					await this.$http
						.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${folderName}/file/upload`, payload)
						// .post(`http://localhost:4030/services/filemanagement/bucket/${folderName}/file/upload`, payload)
						// bucket/:folderName/file/upload
						.then(async (response: any) => {
							if (response.status == 200) {
								console.log(response.data);
								let payload: any = {
									name: `${uuid}.${fileExtension}`,
									url: response.data,
									filename:this.inputFileName
								};
								await this.updateAuditTask(3, payload);
								this.inputFileName = ''
                                this.fileName = ''
                                this.v$.$reset();
							} else {
								console.log('response status', response.status);
							}
						});
				} catch (err) {
					console.error(err);
				} finally {
					this.clickOnButton = false;
				}
			} else {
				toast.error(`Upload only pdf`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.fileName = '';
            this.inputFileName = '';
			this.fileToUpload=null
			
			}
		},
		async updateAuditTask(type: any, payload: any) {
			payload.createdBy = this.userInfo._id;
            payload.createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
			if(type == 1){
				payload.additionalInfo = {comment:''};
				payload.additionalInfo.comment = payload.comment;
			}else if(type == 2){
				payload.additionalInfo = {findingId:''};
				payload.additionalInfo.findingId = payload.findingId;
			}else if(type == 3){
				payload.additionalInfo = {evidence:''};
				payload.additionalInfo.evidence = payload.filename;
			}else if(type == 4){
				payload.additionalInfo = {previousStatus:'',updatedStatus:''};
				payload.additionalInfo.previousStatus = this.presentTaskObject.statusCode;
				payload.additionalInfo.updatedStatus = payload.statusCode;
			}else if(type == 5){
				payload.additionalInfo = {assignee:''};
				payload.additionalInfo.assignee = payload.assignee;
			}
			try {
				await this.$http.post(`${process.env.VUE_APP_AUDIT_API_URL}/task/${this.$route.params.taskId}/type/${type}/update`, payload).then(async (result: any) => {
					console.log('updated');
					toast.info(`Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					await this.getPresentAuditObject();
					await this.getPresentTaskObject(this.$route.params.auditId,this.$route.params.taskId);
					await this.getTaskHistoryObject();
					await this.getAuditFindings(this.$route.params.auditId,this.$route.params.taskId)
				});
			} catch {
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButtonForFinding = false;
			}
		},
		async getConvertDataForFile(data: any) {
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		 colorAuditText(status: any) {
			if (status == 10401) {
				return ['font-semibold text-xs text-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['font-semibold text-xs text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-xs text-lightgreen'];
			} else if(status == 10414){
				return ['font-semibold text-xs mx-2 text-electricGreen']
			} else if(status == 10416){
				return ['font-semibold text-xs mx-2 text-primary']
			}else if(status == 10417 || status == 10418){
				return ['font-semibold text-xs mx-2 text-darkred']
			}
		},
		colorAuditBg(status: any) {
			if (status == 10401) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10414) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10416) {
				return ['evidenceUpload border-solid border border-primary'];
			}else if (status == 10417 || status == 10418) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			}
		},
		onCancelbtn() {
			this.v$.$reset();

			(this.findingsObject.frameworks = ''),(this.findingsObject.frameWorkControl = ''), (this.findingsObject.findingCategory = ''), (this.findingsObject.findingComment = ''), (this.findingsObject.findingEvidence = ''),(this.findingsObject.auditResult = '');
		},
		getControlDetails(controlNumber : any) {
			const control = this.allFrameWorkControls.find((item:any) => item.controlNumber === controlNumber);
			return control ? { controlNumber: control.controlNumber, description: control.controlAuditMethodology} : null;
		},
		async getAllControls(){
            await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`)
				.then((result: { data: any }) => {
                    let controls:any = result.data;
                    this.allFrameWorkControls = result.data;
				})
				.catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
				});
				console.log("allFrameWorkControls",this.allFrameWorkControls)
        },
		async saveEnteredFinding() {
			this.v$.$touch();
			if (!this.v$.findingsObject.$invalid) {
				 this.clickOnButtonForFinding = true;
				await this.updateAuditTask(2, this.findingsObject);
				this.showPopUp = !this.showPopUp;
				this.onCancelbtn();
			}
		},
		async getPresentAuditObject() {
			await this.$http
				.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${this.$route.params.auditId}/get`)
				.then((result: { data: any }) => {
					this.presentAuditObject = result.data;
					let teamLeaderObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentAuditObject.teamLeader});
					this.presentAuditObject.teamLeader = teamLeaderObj;

					if(teamLeaderObj != undefined){
						this.TeamLeaderValue = teamLeaderObj.value
					}
					// this.auditFrameworkArray = this.presentAuditObject.frameworks;
					
					
        			
					console.log("presentAuditObject",this.presentAuditObject)
				})
				.catch((error: any) => {
					toast.error(` ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async getPresentTaskObject(aId:any,tId:any) {
			this.tasksForAudit = [];
			this.auditFrameworkArray = []

			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${aId}/tasks/get`).then(async (result: any) => {
				this.tasksForAudit = result.data;

				let present = result.data.find((obj: any) => {
					return obj._id == tId;
				});
				this.presentTaskObject = { ...present };

				// console.log('this.presentTaskObjectthis.presentTaskObject',this.presentTaskObject)

				if(this.presentTaskObject.frameworks != undefined){
					this.presentTaskObject.frameworks.map((obj:any)=>{
						this.auditFrameworkArray.push(obj.framework);
					})
				}


				if((!this.auditFrameworkArray.includes('Internal Controls'))){
					this.auditFrameworkArray.push('Internal Controls');
				}

				this.TaskDetailsDropDown.taskStatus = this.presentTaskObject.statusCode;

				
					let userObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentTaskObject.assignee});
					this.presentTaskObject.assignee = userObj;
					this.presentAssignee = userObj != undefined ? userObj.value : 'N/A';


				this.controlsArray = this.presentTaskObject.frameworks.filter((obj : any)=>{
					return this.presentAuditObject.frameworks.includes(obj.framework)
				})

			   	this.controlsArray.forEach((item: any) => {
					this.controlDetailsArray = item.controls.map((controlNumber: any) => this.getControlDetails(controlNumber)).filter(Boolean);
					item.controlsDetailsArray = this.controlDetailsArray; 
					delete item.controls;
				});
				// evidenceArray
				this.evidenceArray = []
				if(this.presentTaskObject.files){
					this.presentTaskObject.files.forEach((fileObj: any) => {
						this.evidenceArray.push(fileObj.filename)
					});
				}
				console.log("evidenceArray",this.evidenceArray)
				
			});
		},
		async getAuditFindings(aId:any,tId:any){
			this.findingsForTask = []
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${aId}/findings/getall`).then(async (result: any) => {
				let auditFindings = result.data;
				this.findingsForTask = auditFindings.filter((obj:any)=>{return obj.taskId == tId;})
			})
		}
	},
	async mounted(){
		emitter.off('addedComments');
		emitter.on('addedComments',(data: any)=>{
			this.comment = data.comments
			this.addComment()
		})
		
	},
	beforeUnmount() {
		 sessionStorage.removeItem('selectedTab')
	 },
	async created() {
		 const savedTabKey = sessionStorage.getItem('selectedTab');
			if (savedTabKey) {
			const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
				if (savedTab) {
					this.toggleTabs(savedTab);
				}
				else{
					this.selectedTabObj = { displayName: 'Description', id: 1 };
	            	this.selectedTabIndex = 1; 
				}
		}
		else{
			 this.selectedTabObj = { displayName: 'Description', id: 1 };
		     this.selectedTabIndex = 1;
		}
		await this.getAllActiveUsers();
		await this.getAllTeams();
		await this.getAllControls();
		
		
		await this.getPresentAuditObject();
		

		await this.getallEntities();
		await this.getPresentTaskObject(this.$route.params.auditId,this.$route.params.taskId);

		await this.getAuditFindings(this.$route.params.auditId,this.$route.params.taskId)
		
		
		await this.getTaskHistoryObject();
		this.findingsObjectDummy = { ...this.findingsObject };

		if(Object.keys(this.$route.query).includes('myTasks')){
        this.routesArray = [
            { name: 'My Tasks', routeName: 'myTasks' },
			{ name: this.presentTaskObject.taskId, routeName: 'controlEdit' }
        ];
        }else{
           this.routesArray = [
            { name: 'Audit' , routeName: 'audit' },
            { name: this.presentAuditObject.title, routeName: 'view-audit' },
            { name: 'Tasks', routeName: 'start-audit' },
			{ name: this.presentTaskObject.taskId, routeName: 'controlEdit' }
        ];
        }
		// this.navIndexNums = { index: 1, name: 'Description' };
		if(this.presentTaskObject.comments != undefined){
			this.presentTaskObject.comments.sort().reverse();
		}
	}
});
</script>
